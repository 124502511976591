<template>
  <div class="main-page">
    <el-form class="zwx-form" key="msForm" ref="msForm" :model="msForm" label-position="right" @submit.native.prevent>
      <div class="condition-row ">
        <el-form-item label="行业名称：" prop="industryNameLike" label-width="70px">
          <el-input style="width: 150px !important" class="zwx-input" v-model.trim="msForm.industryNameLike" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="编码：" prop="codeNoLike" label-width="42px">
          <el-input style="width: 150px !important" class="zwx-input" v-model.trim="msForm.codeNoLike" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="国标编码：" prop="nationalStandardCode" label-width="72px">
          <el-input style="width: 150px !important" class="zwx-input" v-model.trim="msForm.nationalStandardCode" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="行业分类级别：" label-width="98px">
          <el-checkbox-group class="zwx-checkbox-group" v-model="msForm.industryLevel">
            <el-checkbox class="zwx-checkbox" :label="1">门类</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="2">大类</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="3">中类</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="4">小类</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        </div>
      <div class="condition-row ">
        <el-form-item label="职业病危害风险分类：" label-width="140px">
          <el-checkbox-group class="zwx-checkbox-group" v-model="msForm.riskClassification">
            <el-checkbox class="zwx-checkbox" :label="1">严重</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="2">一般</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="状态：" label-width="42px">
          <el-checkbox-group class="zwx-checkbox-group" v-model="msForm.ifEnable">
            <el-checkbox class="zwx-checkbox" :label="1">启用</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="0">停用</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item>
          <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="query()">查询</el-button>
          <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="addPlatformMenu">添加</el-button>
        </el-form-item>
      </div>
    </el-form>
    <!-- 列表展示部分 -->
    <el-table class="zwx-table" style="width: 100%" row-key="rid+'-only'" :data="dataList" border stripe tooltip-effect="light">
      <el-table-column prop="levelCodeNo" label="层级编码" width="400" header-align="center" align="left"></el-table-column>
      <el-table-column prop="codeNo" label="编码" width="200" header-align="center" align="left"></el-table-column>
      <el-table-column prop="nationalStandardCode" label="国标编码" width="200" header-align="center" align="left"></el-table-column>
      <el-table-column label="行业名称" width="240" header-align="center" align="left">
        <template slot-scope="scope">
          <template>
            <span v-if="getCount('.', scope.row.levelCodeNo) == 0">{{ scope.row.industryName }}</span>
            <span v-if="getCount('.', scope.row.levelCodeNo) == 1" style="margin-left:8px">{{ scope.row.industryName }}</span>
            <span v-if="getCount('.', scope.row.levelCodeNo) == 2" style="margin-left:16px">{{ scope.row.industryName }}</span>
            <span v-if="getCount('.', scope.row.levelCodeNo) == 3" style="margin-left:24px">{{ scope.row.industryName }}</span>
            <span v-if="getCount('.', scope.row.levelCodeNo) == 4" style="margin-left:32px">{{ scope.row.industryName }}</span>
            <span v-if="getCount('.', scope.row.levelCodeNo) == 5" style="margin-left:40px">{{ scope.row.industryName }}</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="industryLevel" label="级别" width="80" header-align="center" align="center" :formatter="industryLevelFormatter"></el-table-column>
      <el-table-column prop="riskClassification" label="风险分类" width="100" header-align="center" align="center" :formatter="riskClassificationFormatter"></el-table-column>
      <el-table-column fixed="right" label="操作" :min-width="$system.calMinWidth({ wordNum: 3, arrowNum: 1, btnNum: 3 })" header-align="left" align="left">
        <template slot-scope="scope">
          <el-button class="zwx-button zwx-button-text-26" type="text" @click="editIndustryClassification(scope.row)" icon="el-icon-arrow-right">编辑</el-button>
          <el-button class="zwx-button zwx-button-text-26" type="text" v-if="scope.row.ifEnable === false" @click="enableOrDisable(scope.row)">启用</el-button>
          <el-button class="zwx-button zwx-button-text-26" type="text" v-if="scope.row.ifEnable === true" @click="enableOrDisable(scope.row)" style="color:rgb(231, 43, 0) !important;">停用</el-button>
          <el-button class="zwx-button zwx-button-text-26 zwx-button-danger" v-if="scope.row.ifEnable === false" type="text" @click="$system.msgbox(scope.row, '提示', '确定要删除吗？', '确定', '取消', deleteIndustryClassification)"><span style="color: #DD3838;">删除</span></el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器部分 -->
    <base-pagination  :parentPage="currentPage" :pageSize="pageSize" :total="total" @currentChange="currentChange" />
  </div>
</template>

<script>
export default {
  name: 'IndustryClassification',
  data() {
    return {
      api: this.$store.state.api,
      dataList: [],
      msForm: {
        industryNameLike: '',
        codeNoLike: '',
        nationalStandardCode:'',
        industryLevel: [],
        riskClassification: [],
        ifEnable: [1,],
      },
      currentPage: 1,
      pageSize: 18,
      total: 0,
    }
  },
  created() {},
  mounted() {
    this.query()
  },
  activated() {
    if (this.$route.params.fresh) this.currentChange(this.currentPage)
  },
  computed: {},
  watch: {},
  methods: {
    /**
     * 查询
     */
    query() {
      this.currentPage = 1
      this.currentChange(1)
    },
    /**
     * 分页查询
     */
    currentChange(currentPage) {
      this.$emit('loading', true)
      this.currentPage = currentPage
      let data = {
        currentPage: currentPage,
        industryNameLike: this.msForm.industryNameLike,
        codeNoLike: this.msForm.codeNoLike,
        nationalStandardCode: this.msForm.nationalStandardCode,
        industryLevelContent: this.msForm.industryLevel.join(',') || null,
        riskClassificationContent: this.msForm.riskClassification.join(',') || null,
        ifEnableContent: this.msForm.ifEnable.join(',') || null,
      }
      this.$system.get(
        this.api + '/oh/repository/getIndustryList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.dataList = data.industryClassificationList
            this.total = data.industryClassificationCount
            this.pageSize = data.pageSize
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 启用/停用
     */
    enableOrDisable(row) {
      var mess = row.ifEnable ? '停用' : '启用'
      this.$system.msgbox('', '提示', '是否确定' + mess + '？', '确定', '取消', () => {
        let data = {
          rid: row.rid,
          ifEnable: row.ifEnable ? false : true,
        }
        this.$emit('loading', true)
        this.$system.postJson(
          this.api + '/oh/repository/enableOrDisableIndustryClassificationByID-1',
          data,
          true,
          true,
          data => {
            this.$emit('loading', false)
            if (data.type == '00') {
              this.$system.notify('成功', data.mess, 'success')
              this.currentChange(this.currentPage)
            } else {
              this.$system.notify('错误', data.mess, 'error')
            }
          },
          data => {
            this.$emit('loading', false)
            this.$system.notify('错误', data.mess, 'error')
          }
        )
      })
    },

    /**
     * 删除行业分类
     */
    deleteIndustryClassification(row) {
      let data = {
        rid: row.rid,
      }
      this.$emit('loading', false)
      this.$system.post(
        this.api + '/oh/repository/deleteIndustryClassificationByID-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.$system.notify('成功', data.mess, 'success')
            this.query()
            this.$emit('loading', false)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    //添加
    addPlatformMenu(row) {
      this.$router.push({ name: 'IndustryClassificationEdit', params: { type: 'add' } })
    },
    //编辑
    editIndustryClassification(row) {
      this.$router.push({ name: 'IndustryClassificationEdit', params: { rid: row.rid, type: 'edit' } })
    },
    /**
     * 行业分类级别处理
     */
    industryLevelFormatter(row, column, cellValue, index) {
      if (cellValue == 1) {
        return '门类'
      }
      if (cellValue == 2) {
        return '大类'
      }
      if (cellValue == 3) {
        return '中类'
      }
      if (cellValue == 4) {
        return '小类'
      }
    },
    /**
     * 职业病危害风险分类处理
     */
    riskClassificationFormatter(row, column, cellValue, index) {
      if (cellValue == 1) {
        return '严重'
      }
      if (cellValue == 2) {
        return '一般'
      }
    },
    /**
     * 获取menuNo层级
     */
    getCount(SubChar, StrTemp) {
      let count, i
      count = 0
      for (i = 0; i < StrTemp.length; i++) {
        if (StrTemp.charAt(i, 1) == SubChar) {
          count = count + 1
        }
      }
      return count
    },
  },
}
</script>

<style lang="less" scoped></style>
